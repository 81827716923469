import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


var controller = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onEnter',
  // duration: 800,
  offset :0
})
// .addIndicators()
.addTo(controller);

scene_hero.on('enter', ()=>{
//triggerElementを過ぎたとき
});
scene_hero.on('leave', ()=>{
//triggerElementを抜けたとき
});




let targets_business = document.querySelectorAll('.top-business__item')

for (let target of targets_business) {

  var business_item = new ScrollMagic.Scene({
    triggerElement: target,
    triggerHook: 'onEnter',
    // duration: 800,
    offset: '100px'
  })
    // .addIndicators()
    .addTo(controller);

  business_item.on('enter', () => {

	  target.classList.add('is-animated');

  });

}

let targets_company = document.querySelectorAll('.top-company')

for (let target of targets_company) {

  var company = new ScrollMagic.Scene({
    triggerElement: target,
    triggerHook: 'onEnter',
    // duration: 800,
    // offset: '100px'
  })
    // .addIndicators()
    .addTo(controller);

  company.on('enter', () => {

	  target.classList.add('is-animated');

  });

}


let targets_news = document.querySelectorAll('.c-news-list__item')

for (let target of targets_news) {

  var newslist = new ScrollMagic.Scene({
    triggerElement: target,
    triggerHook: 'onEnter',
    // duration: 800,
    offset: '100px'
  })
    // .addIndicators()
    .addTo(controller);

  newslist.on('enter', () => {

	  target.classList.add('is-animated');

  });

}
