import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";
import _ua from './is-ua';
import parallax_m from './parallax-m';

import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import luxy from 'luxy.js';

// 使い方
// htmlに以下のclassとdata属性を指定
// class="m-parallax"
// data-speed="15" data-limit="250" data-selector="top-search__background" data-reverse="true"

// data-speed 値が大きいほど遅くなる
// data-limit 可動範囲 250だと250pxまで動く
// data-selector 基準にするセレクターを変更
// data-reverse trueで動きが反転


function transform3d_value(e) {
  var values = e.split('(')[1];
  values = values.split(')')[0];
  values = values.split(', ');
  var matrix = {
    'scale-x': values[0],
    'rotate-z-p': values[1],
    'rotate-y-p': values[2],
    'perspective1': values[3],
    'rotate-z-m': values[4],
    'scaleY': values[5],
    'rotate-x-p': values[6],
    'perspective2': values[7],
    'rotate-y-m': values[8],
    'rotate-x-m': values[9],
    'scale-z': values[10],
    'perspective3': values[11],
    'translate-x': values[12],
    'translate-y': values[13],
    'translate-z': values[14],
    'perspective4': values[15]
  };
  return matrix;
}

var controller = new ScrollMagic.Controller();

// console.log(document.querySelectorAll(".m-parallax"));

if($("#js-inertiaScroll").length && $(".m-parallax").length == 0) {
  luxy.init({
    wrapper: '#js-inertiaScroll',
    wrapperSpeed: 0.10
  });
}


if ($(".m-parallax").length) {
  let targets = document.querySelectorAll(".m-parallax");
  $(".m-parallax").each(function (i, v) {

    let that = $(this);
    let speed = targets[i].getAttribute('data-speed');
    let limit = targets[i].getAttribute('data-limit');
    let reverse = targets[i].getAttribute('data-reverse');
    let selector = targets[i].getAttribute('data-selector') ? "." + targets[i].getAttribute('data-selector') : targets[i];
    // console.log(targets[i]);

    var parallax = new ScrollMagic.Scene({
      triggerElement: selector,
      triggerHook: 'onEnter',
      // duration: 800,
      offset: 0
    })
      // .setTween(".top-spa", 2, {opacity:0})  // #block1の要素に到達した際に、対象要素のopacityを2秒かけて0にします
      // TweenMaxの関数、toと同様の指定が可能です。
      // .addIndicators()
      .addTo(controller);

    parallax.on('enter', () => {
      // console.log(parallax.scrollOffset());
      // console.log($(window).scrollTop());
      var flag = $("#js-inertiaScroll").length > 0 ? $("#js-inertiaScroll").css("transform") : "none";
      var props = flag == "none" ? "none" : transform3d_value($("#js-inertiaScroll").css("transform"));
      // console.log(props);
      var transform3dScaleY = props == "none" ? 0 : props.scaleY;
      var scrollTop = parallax.scrollOffset() < 0 ? 0 : parallax.scrollOffset();
      var offset_ex = parallax.scrollOffset() > transform3dScaleY * -1 ? transform3dScaleY * -1 : scrollTop;
      var offset = flag == "none" ? scrollTop : offset_ex;
      parallax_m(targets[i], offset, speed, limit, flag, reverse);
      // TweenMax.to('.top-spa', 2, {opacity:0});
      // console.log(scene_spa.scrollOffset());
    });

  })

//慣性スクロール実行
  if ($("#js-inertiaScroll").length) {
    if(!_ua().Mobile&&!_ua().Tablet){
      luxy.init({
        wrapper: '#js-inertiaScroll',
        wrapperSpeed: 0.10
      });
    }
  }
}




