import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";

export default function parallax_m(selector, offset, speed, limit, inertia, reverse) {
  $(window).on('scroll load resize', function () {
    // scrollTop = $win.scrollTop();
    if (limit > Math.abs(($(window).scrollTop() - offset) / speed)) {
      if (!reverse) {
        if (inertia == "none") {
          $(selector).css({
            'transform': 'translateY(' + (($(window).scrollTop() - offset) / speed) + 'px)'
          })
        } else {
          TweenMax.to(selector, 1.08, { y: (($(window).scrollTop() - offset) / speed), ease: Power1.easeOut });
        }
      } else {
        if (inertia == "none") {
          $(selector).css({
            'transform': 'translateY(' + -(($(window).scrollTop() - offset) / speed) + 'px)'
          })
        } else {
          TweenMax.to(selector, 1.08, { y: (-($(window).scrollTop() - offset) / speed), ease: Power1.easeOut });
        }
      }
    }
  })
}
