import isMobile from './is-mobile.js';

//グローバルナビ メガメニュー
$('.js-megamenu .megamenu-wrapper').on({
  'touchstart mouseenter': function touchstartMouseenter() {
    if(!isMobile(768)){
      $(this).addClass('is-megaMenu--show');
      $("body").addClass('is-megaMenu--showPage');
      $(this).next().find(".megamenu-content").css({"z-index": 500});
      $(this).prev().find(".megamenu-content").css({"z-index": 500});
    }
  },
  'touchend mouseleave': function touchendMouseleave() {
    if(!isMobile(768)){
      $(this).removeClass('is-megaMenu--show');
      $("body").removeClass('is-megaMenu--showPage');
      $(this).next().find(".megamenu-content").attr({"style": ""});
      $(this).prev().find(".megamenu-content").attr({"style": ""});
    }
  }
});
//グローバルナビ メガメニュー スマホで親をタップしてもリンクしない
$('.childOpenBtn').on("click", function(e){
  if(isMobile(768)){
    // if($(e.target).closest(".megamenu-wrapper")) {
	// 	console.log('hogehig');
      $(this).closest(".megamenu-wrapper").toggleClass('is-megaMenu--show');
    // }
    // return ! $(e.target).closest(".megamenu-wrapper")
  }
})

const hoge = val => val + 1;
console.log(hoge(1));

$(function() {
  var _window = $(window),
  _header = $('#l-header'),
  heroBottom;

  _window.on('scroll',function(){
    // heroBottom = $('.top-hero').height();
    if(_window.scrollTop() > 500){
      _header.addClass('is-animation');
    }
    else{
      _header.removeClass('is-animation');
    }
  });

});
